@import 'import';
$root: '.WhatsIncluded';

#{$root} {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;
  margin: rem(30) 0;
  padding: rem(20);

  background: linear-gradient(0deg, $WhatsIncluded-gradient-start-point 0%, $WhatsIncluded-gradient-end-point 100%);
  border-radius: rem(8);

  &__header {
    position: relative;

    margin-bottom: rem(19);

    font-size: rem(15);
    font-weight: bold;
    color: $white;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: rem(22);
    }
  }

  &__focusableButtonContent {
    position: absolute;
    inset: 0;
    background: transparent;
    border: 0;
  }

  &__list {
    position: relative;

    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 0;

    text-align: center;

    @include media-breakpoint-up(xs) {
      flex-wrap: nowrap;
    }

    @include media-breakpoint-up(sm) {
      gap: 20px;
      padding: 0 20px;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 40px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:focus-visible {
        outline-color: $white;
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }

      &__image {
        width: rem(35);
        filter: brightness(2);

        @include media-breakpoint-up(md) {
          margin-bottom: rem(10);
        }

        @include media-breakpoint-up(lg) {
          margin-right: rem(10);
          margin-bottom: 0;
        }
      }

      &__text {
        display: none;

        width: 100%;

        font-size: rem(13);
        font-weight: 400;
        color: $white;

        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }
  }
}
